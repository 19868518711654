<template>
    <v-app class="fondo">
        <div class="container-app principal">
            <div class="container-quiz">
                <div class="quiz-header">
                    <v-img aspect-ratio="1" max-width="80" max-height="80" src="/logosurveys.png" ></v-img>
                </div>
                <div class="step-progress" :style="{'width': progress + '%'}">
                </div>
                <div class="quiz-main " v-for="(element, index) in preguntas.slice(a,b)" :key="index" v-show="quiz">
                    <div class="quiz-body">
                        <div class="box-question ma-0">
                            <p class="ma-0 text-h5 grey--text text--darken-1 font-weight-medium">Pregunta {{b}}/{{preguntas.length}}</p>
                            <p class="ma-0 text-headline grey--text text--darken-1"><span class="green--text text--darken-3 font-weight-bold">{{element.question}}</span></p>
                        </div>
                        <div class="box-suggestions ma-3" v-if="element.tipoPregunta == 'cerrada'">
                            <v-container
                            style="max-height: 220px"
                            class="overflow-y-auto mr-5"
                            >
                                <v-row
                                    align="center"
                                    justify="center"
                                >
                                    <ul>
                                        <li class="ma-1 text-body-1 font-weight-light" v-for="(item, index) in element.opciones" :key="index" :class="item.class" @click="selectResponse(item)">
                                            {{item.opcion}}
                                        </li>
                                    </ul>
                                </v-row>
                            </v-container>
                        </div>
                        <div v-else>
                            <v-container fluid>
                                <v-textarea
                                name="input-7-1"
                                filled
                                label="Escriba aquí su respuesta"
                                auto-grow
                                v-model="answerText"
                                ></v-textarea>
                            </v-container>
                        </div>
                    </div>
                    <div class="quiz-footer2">
                        <div class="box-button" v-if="progress < 100">
                            <!-- <button @click="skipQuestion()" :style="!next ? 'background-color:rgb(106, 128, 202)':''">Omitir</button> -->
                            <v-btn @click="nextQuestion(element)" :style="next ? 'background-color:rgba(129,199,132,1); color: #fff !important;':''" :class= "verifyStatus"
                            text        
                            class="py-6 mr-2 grey--text text-h6 font-weight-bold">Siguiente
                                <v-icon
                                right
                                dark
                                x-large
                                >
                                mdi-chevron-right
                                </v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>
                <div class="box-score" v-if="score_show">
                    <p class="pt-15 grey--text text--darken-1">Gracias por responder las preguntas</p>
                    <v-row class="text-center" justify="center">
                        <v-col
                        cols="12"
                        md="12"
                        >
                            <div
                                class="green darken-3 rounded-circle d-inline-block"
                            >
                                <p class="mx-7 my-7 text-body-1 white--text text--lighten-3 font-weight-medium">{{score}}/{{preguntas.length}}</p>
                            </div>
                            <p class="mt-5">
                                <v-icon color="green darken-3" class="px-5" style="vertical-align: middle">
                                    mdi-gamepad-circle
                                </v-icon> <span class=" text-h6 grey--text text--darken-1"> Haz finalizado tu <span class="green--text text--darken-3">Segunda Encuesta</span></span>
                                <v-icon color="green darken-3" class="px-5" style="vertical-align: middle">
                                    mdi-gamepad-circle
                                </v-icon>
                            </p>
                            <p class="my-2 text-caption grey--text text--darken-1 font-weight-medium">Haz click en finalizar para terminar con la encuesta</p>
                        </v-col>
                    </v-row>

                    <div>
                        <v-btn
                        rounded
                        class="py-6 grey--text text-h6 font-weight-bold"
                        color="green lighten-3"
                        @click="finishQuiz()"
                        style="background-color:rgba(129,199,132,1); color: #fff !important;"
                        >
                        Finalizar
                            <v-icon
                                    right
                                    dark
                                    x-large
                                >
                                mdi-chevron-right
                            </v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
import {mapActions, mapGetters,mapState} from 'vuex';

export default {
    data() {
        return {
            preguntas:[],
            evaluatedInfo : [],
            a:0,
            b:1,
            select:false,
            score: 0,
            quiz:true,
            score_show: false,
            next:false,
            progress: 0,
            answer: [],
            typeClose: 0,
            typeOpen: 0,
            answerText: ''
        }
    },
    computed: {
        ...mapState(['token']),
        verifyStatus(){
            return (this.typeClose + this.typeOpen >= this.preguntas.length)?this.next = true: this.next = false
        }
    },
    created() {
        this.getQuestions()
    },
    methods: {
        getQuestions(){
            let config = {
                headers:{
                    token: this.token
                }
            }
            this.axios.get('/evaluatedInfo', config).then(response =>{
                this.evaluatedInfo = response.data
                this.axios.post('/encuestadoredirectsurvey2', this.evaluatedInfo)
                    .then(res => {
                        if(res.data){
                            this.$router.push({name: 'instruction'})
                        }else{
                            this.axios.get(`/surveyencuesta2/${response.data.encuestaId}`)
                                    .then(res => {
                                        this.preguntas = res.data;
                                        for(let i=0;i<res.data.length; i++){
                                            if(res.data[i].tipoPregunta == 'abierta'){
                                                this.typeOpen ++;
                                            }
                                        }
                                    })
                                    .catch(e => {
                                        console.log(e.response);
                                    })
                        }
                    })
                    .catch(e => {
                    console.log(e.response);
                    })
            });
        },
        selectResponse(e){
            this.answer = "";
            for(let i=0; i<this.preguntas.length; i++){
                for(let j=0; j<this.preguntas[i].opciones.length; j++){
                    this.preguntas[i].opciones[j].class = ''
                    if(this.preguntas[i].opciones[j].valor == e.valor){
                        this.preguntas[i].opciones[j].class = 'correct'
                    }
                }
            }
            
            this.select = true;
            this.next = true;

            this.answer = e;
        },
        nextQuestion(info){
            if (!this.next) { // Si es falso, return
                return;
            }

            for(let i=0; i<this.preguntas.length; i++){
                for(let j=0; j<this.preguntas[i].opciones.length; j++){
                    this.preguntas[i].opciones[j].class = ''
                }
            }

            //save answer
            if(info.tipoPregunta == 'abierta'){
                if(this.answerText.length != 0){
                    this.answer.encuestadoId = this.evaluatedInfo.encuestadoId
                    this.answer.encuestaId = this.evaluatedInfo.encuestaId
                    this.answer.procesoId = this.evaluatedInfo.procesoId
                    this.answer.test = 2
                    this.answer.texto = this.answerText
                    this.answer.questionId = info.questionId
                    delete this.answer.opcion
                    delete this.answer.valor
                    this.axios.post('/encuestadoanswer2', this.answer)
                        .then(res => {
                            this.answerText = ''
                            console.log('Saved')
                        })
                        .catch(e => {
                            console.log(e.response);
                        })
                }
            }else{
                this.answer.encuestadoId = this.evaluatedInfo.encuestadoId
                this.answer.encuestaId = this.evaluatedInfo.encuestaId
                this.answer.procesoId = this.evaluatedInfo.procesoId
                this.answer.test = 2
                this.axios.post('/encuestadoanswer2', this.answer)
                    .then(res => {
                        console.log('Saved')
                    })
                    .catch(e => {
                        console.log(e.response);
                    })
                
            }
            this.typeClose ++; //se activa el computed verifyStatus, es necesario utilizar

            this.progress = this.progress + (100/this.preguntas.length);
            if(this.preguntas.length - 1 == this.a){
                this.score_show = true;
                this.quiz = false;
            }else{
                this.a++;
                this.b++;
                this.select = false;
                this.next = false;
            }
            this.score ++;
            
        },
        finishQuiz(){
            this.axios.post('/encuestadontestFinish2', this.evaluatedInfo)
                .then(res => {
                        console.log(res.data);
                        this.$router.push({name: 'instruction'})
                    })
                    .catch(e => {
                    console.log(e.response);
                    })
        }
    },
}
</script>
<style>
.principal {
  margin-top: 8%;
}
#app {
  background: url('../assets/man.png');
  background-size: cover;
  background-size:100% 100%;
}
.image {
  
  background-size:100% 100%;
}
</style>