<template>
    <v-card
    max-width="374"
  >
    <div class="pt-5">
        <v-img class="align-center justify-center mx-auto" width="210px"  src="/survey.png" ></v-img>
        <v-img class="mt-10 align-center justify-center mx-auto" width="100px" height="100px" src="/contento4.png" ></v-img>
        <v-card-title class="ml-12 text-caption green--text text--darken-3 font-weight-bold">Código de Usuario Anónimo: </v-card-title>
        <v-card-subtitle class="ml-16 pl-8 text-subtitle-1 gray--text text--lighten-3 font-weight-light">{{ evaluated.user}}</v-card-subtitle>
    </div>
    <v-card-actions class="pb-7 align-center justify-center mx-auto">
        <v-btn
            outlined
            color="#e57373"
            class="text-capitalize"
            bottom
            @click="cerrarSesion()"
        >
            Cerrar Sesión
            <v-icon color="#e57373"
            fab
            medium
            class="ml-3"
            >mdi-close-circle</v-icon>
        </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapGetters,mapState} from 'vuex'
export default {
    data() {
        return {
            evaluated: [],
        }
    },
    computed: {
        ...mapState(['token'])
    },
    created() {
        this.getEvaluatedData();
    },
    methods: {
        ...mapActions(['cerrarSesion','leerToken','guardarUsuario']),
        getEvaluatedData(){
            let config = {
                headers:{
                    token: this.token
                }
            }
            this.axios.get('/evaluatedInfo', config).then(response =>{
                this.evaluated = response.data;
            });
        }
    }
};
</script>