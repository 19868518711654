<template>
    <v-app class="fondo">
        <div class="container-app principal">
            <div class="container-quiz">
                <div class="quiz-header">
                    <v-img  aspect-ratio="1" max-width="80" max-height="80" src="/logosurveys.png" ></v-img>
                </div>
                <div class="step-progress" :style="{'width': progress + '%'}">
                </div>
                <div class="quiz-main" v-for="(element, index) in preguntas.slice(a,b)" :key="index" v-show="quiz">
                    <div class="box-question">
                        <p class="ma-0 text-h5 grey--text text--darken-1 font-weight-medium">Pregunta {{b}}/{{preguntas.length}}</p>
                        <p class="ma-0 text-headline grey--text text--darken-1"><span class="green--text text--darken-3 font-weight-bold">{{element.question}}</span></p>
                    </div>
                    <div class="box-suggestions ma-3">
                        <v-container
                        style="max-height: 220px"
                        class="overflow-y-auto mr-5"
                        >
                            <v-row
                                align="center"
                                justify="center"
                            >
                                <ul>
                                    <li class="ma-1 text-body-1 font-weight-light" v-for="(item, index) in element.opciones" :key="index" :class="item.class" @click="selectResponse(item)">
                                        {{item.atributoName}} 
                                    </li>
                                </ul>
                            </v-row>
                        </v-container>
                        
                    </div>
                </div>
                <div class="box-score" v-if="score_show">
                    <p class="pt-15 grey--text text--darken-1">Gracias por responder las preguntas</p>
                    <v-row class="text-center" justify="center">
                        <v-col
                        cols="12"
                        md="12"
                        >
                            <div
                                class="green darken-3 rounded-circle d-inline-block"
                            >
                                <p class="mx-7 my-7 text-body-1 white--text text--lighten-3 font-weight-medium">{{score}}/{{preguntas.length}}</p>
                            </div>
                            <p class="mt-5">
                                <v-icon color="green darken-3" class="px-5" style="vertical-align: middle">
                                    mdi-gamepad-circle
                                </v-icon> <span class=" text-h6 grey--text text--darken-1"> Haz finalizado tu <span class="green--text text--darken-3">Primera Encuesta</span></span>
                                <v-icon color="green darken-3" class="px-5" style="vertical-align: middle">
                                    mdi-gamepad-circle
                                </v-icon>
                            </p>
                            <p class="my-2 text-caption grey--text text--darken-1 font-weight-medium">Haz click en continuar para pasar a la siguiente</p>
                        </v-col>
                    </v-row>
                    
                    <div>
                        <v-btn
                        rounded
                        class="py-6 grey--text text-h6 font-weight-bold"
                        absolute
                        right
                        @click="finishQuiz()"
                        style="background-color:rgba(129,199,132,1); color: #fff !important;"
                        >
                        Continuar
                            <v-icon
                                right
                                dark
                                x-large
                            >
                            mdi-chevron-right
                            </v-icon>
                        </v-btn>
                    </div>
                </div>
                <div class="quiz-footer">
                    <div class="box-button" v-if="progress < 100">
                        <v-btn @click="nextQuestion()" :style="next ? 'background-color:rgba(129,199,132,1); color: #fff !important;':''" 
                            text
                            class="py-6 mr-4 grey--text text-h6 font-weight-bold">Siguiente
                            <v-icon
                                right
                                dark
                                x-large
                            >
                            mdi-chevron-right
                            </v-icon>
                        </v-btn >
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
import {mapActions, mapGetters,mapState} from 'vuex'
export default {
    data() {
        return {
            preguntas:[],
            evaluatedInfo : [],
            a:0,
            b:1,
            select:false,
            score: 0,
            quiz:true,
            score_show: false,
            next:false,
            progress: 0,
            answer: [],
        }
    },
    computed: {
        ...mapState(['token'])
    },
    components: {

    },
    created() {
        this.getQuestions();
    },
    methods: {
        getQuestions(){
            let config = {
                headers:{
                    token: this.token
                }
            }
            this.axios.get('/evaluatedInfo', config).then(response =>{
                this.evaluatedInfo = response.data
                this.axios.post('/encuestadoredirectsurvey1', this.evaluatedInfo)
                    .then(res => {
                        if(res.data){
                            this.$router.push({name: 'instruction'})
                        }else{
                            this.axios.get(`/surveyencuesta1/${response.data.encuestaId}`)
                                .then(res => {
                                    console.log(res.data);
                                    this.preguntas = res.data;
                                })
                                .catch(e => {
                                    console.log(e.response);
                                })
                        }
                    })
                    .catch(e => {
                    console.log(e.response);
                    })
            });

            
        },
        selectResponse(e){
            this.answer = "";
            for(let i=0; i<this.preguntas.length; i++){
                for(let j=0; j<this.preguntas[i].opciones.length; j++){
                    this.preguntas[i].opciones[j].class = ''
                    if(this.preguntas[i].opciones[j].atributoId == e.atributoId){
                        this.preguntas[i].opciones[j].class = 'correct'
                    }
                    
                    this.previous_active_id = e.atributoId
                }
            }
            this.select = true;
            this.next = true;
            
            this.respuesta= -1;
            this.answer = e;
        },
        nextQuestion(){

            if (!this.next) {
                return;
            }
            
            //save answer
            this.answer.encuestadoId = this.evaluatedInfo.encuestadoId
            this.answer.encuestaId = this.evaluatedInfo.encuestaId
            this.answer.procesoId = this.evaluatedInfo.procesoId
            this.answer.test = 1
            this.axios.post('/encuestadoanswer1', this.answer)
                .then(res => {
                    console.log(res.data)
                })
                .catch(e => {
                console.log(e.response);
                })

            this.progress = this.progress + (100/this.preguntas.length);

            if(this.preguntas.length - 1 == this.a){
                this.score_show = true;
                this.quiz = false;
            }else{
                this.a++;
                this.b++;
                this.select = false;
                this.next = false;
            }
            this.score ++;
        },
        finishQuiz(){
            this.axios.post('/encuestadontestFinish1', this.evaluatedInfo)
                .then(res => {
                        console.log(res.data);
                        this.$router.push({name: 'instruction'})
                    })
                    .catch(e => {
                    console.log(e.response);
                    })
        },

    }
}
</script>
<style>
.principal {
  margin-top: 8%;
}
#app {
  background: url('../assets/man.png');
  background-size: cover;
  background-size:100% 100%;
}
.image {
  
  background-size:100% 100%;
}
</style>