<template>
    <v-app class="fondo">
        <v-container class="grey lighten-5 principal">
            <v-row no-gutters>
                <v-col cols="12" md="3" sm="6">
                    <Navbar />
                </v-col>
                <v-col cols="12" sm="6" md="9" v-if="authorized == true && !loading">
                    <v-card class="mx-auto">
                        <h2 class="px-10 py-4 grey--text text--darken-0 font-weight-regular">Bienvenido a la encuesta de
                            <span class="green--text text--darken-3">{{encuestaName.encuesta}}</span>
                        </h2>
                        <p class="px-15 py-2 text-h6 green--text text--darken-3 font-weight-light">Indicaciones:</p>
                        <div class="ml-3">
                            <v-row>
                                <v-col cols="12" md="1" sm="6" class="pl-15">
                                    <v-row class="ma-0 pa-0">
                                        <v-icon small color="green darken-3" class="reduce-icon">
                                            mdi-checkbox-blank-circle
                                        </v-icon>
                                        <v-icon size="8" color="green darken-3" class="ml-4 pb-6">
                                            mdi-checkbox-blank-circle
                                        </v-icon>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="10" sm="6">
                                    <p class="ml-5 text-subtitle-2 grey--text text--darken-0 font-weight-light">Esta
                                        encuesta es totalmente anónima, por tal motivo, te solicitamos que respondas con
                                        toda sinceridad cada una de las preguntas. Es muy importante para nosotros conocer
                                        sus opiniones.</p>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="ml-3">
                            <v-row>
                                <v-col cols="12" md="1" sm="6" class="pl-15">
                                    <v-row class="ma-0 pa-0">
                                        <v-icon small color="green darken-3" class="reduce-icon">
                                            mdi-checkbox-blank-circle
                                        </v-icon>
                                        <v-icon size="8" color="green darken-3" class="ml-4 pb-6">
                                            mdi-checkbox-blank-circle
                                        </v-icon>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="10" sm="6">
                                    <p class="ml-5 text-subtitle-2 grey--text text--darken-0 font-weight-light">Sus
                                        respuestas a la encuesta serán estrictamente confidenciales y los datos de esta
                                        investigación se informarán solo en conjunto. Su información estará codificada y
                                        permanecerá confidencial.</p>
                                </v-col>
                            </v-row>
                        </div>

                        <v-card-actions class="pb-10">
                            <v-row>
                                <v-col class="text-right">
                                    <v-btn rounded color="green lighten-2"
                                        class="py-6 mr-2 white--text text-h6 font-weight-light" to="/instruction">
                                        INICIAR
                                        <v-icon right dark x-large>
                                            mdi-chevron-right
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="9" v-if="authorized == false && !loading">
                    <v-card class="mx-auto">
                        <h2 class="px-10 py-4 grey--text text--darken-0 font-weight-regular">{{ unathorizedMessage }}</h2>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="9" v-if="loading">
                    <v-card class="mx-auto">
                        <h2 class="px-10 py-4 grey--text text--darken-0 font-weight-regular">Cargando...</h2>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>


<script>

import Navbar from '../components/Navbar'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    data() {
        return {
            authorized: false,
            loading: true,
            unathorizedMessage: 'Esta encuesta aún no se encuentra habilitada.',
            encuestaName: ''
        }
    },
    computed: {
        ...mapState(['token'])
    },
    components: {
        Navbar
    },
    methods: {
        isAuthorized() {
            let config = {
                headers: {
                    token: this.token
                }
            }
            this.axios.get('/evaluatedInfo', config).then(response => {
                const procesoId = response.data.procesoId;
                this.axios.get(`${process.env.VUE_APP_BACKEND_NEW}/procesos/${procesoId}`).then(response => {
                    console.log(response.data);
                    this.encuestaName = response.data;
                    const proceso = response.data;
                    if (proceso.fechaFin == null) {
                        this.authorized = true;
                        this.loading = false;
                    } else {
                        const fechaInicio = new Date(proceso.fechaInicio);
                        const fechaFin = new Date(proceso.fechaFin);
                        const fechaActual = new Date();
                        console.log(fechaFin, fechaActual);
                        this.loading = false;
                        if (fechaActual < fechaFin && fechaActual > fechaInicio) {
                            this.authorized = true;
                        } else {
                            this.authorized = false;
                            if (fechaActual > fechaFin) {
                                this.unathorizedMessage = 'Esta encuesta finalizó el ' + fechaFin.toLocaleDateString() + '.';
                            } else {
                                this.unathorizedMessage = 'Esta encuesta se habilitará el ' + fechaInicio.toLocaleDateString() + ' a las ' + fechaInicio.toLocaleTimeString() + ' horas.';
                            }
                        }
                    }
                });
            });
        }
    },
    mounted() {
        this.isAuthorized();
    }
}
</script>
<style>
.principal {
    margin-top: 8%;
}

#app {
    background: url('../assets/fondo1.png');
    background-size: cover;
}

.reduce-space {
    margin: -43px -10px;
}

.reduce-icon {
    margin-bottom: -6px;
}
</style>
